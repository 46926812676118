<template>
    <span>
        <button
            class="btn btn-sm btn-info"
            :class="{ 'hide': hideButton }"
            ref="mediaSelectButton"
            @click="toggleList"
        >
            <i class="fas fa-images"></i>
            {{ $t(selectLabel) }}
        </button>
        <app-modal
            v-if="showList"
            :title="$t(selectLabel)"
            @close="toggleList"
        >
            <app-gallery-list
                slot="body"
                modal
                @select="select"
                @detail="detail"
            ></app-gallery-list>
        </app-modal>
        <app-modal
            v-if="showDetail"
            :title="$t('route.gallery_detail')"
            @close="toggleDetail"
            class="modal-scroll"
        >
            <app-gallery-detail slot="body" modal></app-gallery-detail>
        </app-modal>
    </span>
</template>

<script>
import appModal from '../../components/shared/Modal'
import appGalleryDetail from '../../views/gallery/GalleryView'
import appGalleryList from '../../views/gallery/GalleryListView'

export default {
  name: 'MediaSelectButton',
  props: {
    article: {
      type: Object
    },
    hideButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showList: false,
      showDetail: false
    }
  },
  components: {
    appModal,
    appGalleryDetail,
    appGalleryList
  },
  computed: {
    selectLabel () {
      return this.article.galleryGroup === null ? 'gallery.select' : 'gallery.reselect'
    }
  },
  methods: {
    toggleList () {
      this.showList = !this.showList
    },
    toggleDetail () {
      this.showDetail = !this.showDetail
    },
    async select (gallery) {
      await this.$store.dispatch('gallery/fetchOne', gallery.id)
      // this ensures in ArticleEditView.vue that new widen-medias will be created to prevent sharing of widen-media across articles
      this.$emit('add-media-to-gallery', this.$store.getters['gallery/detail'].expanded.medias)
      this.article.galleryGroup = gallery.id
      this.toggleList()
    },
    async detail (gallery) {
      await this.$store.dispatch('gallery/fetchOne', gallery.id)
      this.toggleDetail()
    }
  }
}
</script>

<style lang="scss">
    .modal-scroll .modal-content {
        max-height: calc(100vh - 60px);
    }
    .modal-scroll .modal-body {
        overflow: auto;
    }
</style>
